import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDateInput]'
})
export class DateInputDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;

    // Allow only letters, digits, -, /, . and space characters
    let value = input.value.toUpperCase().replace(/[^A-Z0-9\/\-. ]/g, '');

    // Set the validated value back to the input
    input.value = value;
  }
}
